import React from 'react'
import { Link } from "gatsby"

export default function ContactTeaser() {
  return (
    <div className="no-print text-light">
        <form className="container px-2 py-5 d-flex flex-column align-items-center">
          <div className="d-flex justify-content-center">
            <div className="form-inline d-flex my-4 align-items-baseline" style={{ fontSize: '125%' }}>
              <label className="mr-2 flex-fill" for="role">I want to hire Erik for&nbsp;
                <div className="d-flex align-items-baseline">
                  <select id="role" className="form-control flex-shrink-1">
                    <option>anything</option>
                    <option>software development</option>
                    <option>product development</option>
                    <option>project management</option>
                    <option>UX/UI design</option>
                    <option>technology consulting</option>
                  </select>
                  &nbsp;.
                </div>
              </label>
            </div>
          </div>
          <Link to="/contact" className="btn btn-outline-primary">Contact Erik</Link>
        </form>
      </div>
  )
}