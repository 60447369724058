import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { graphql, StaticQuery } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = function Template({
  children
}) {
  return <div className="bg-white">
      <div className="container py-5 px-5">
        <div className="col-12 d-flex flex-column align-items-center mb-3">
          <h2>Who is Erik Altman?</h2>
          <div className="my-3" style={{
          borderBottom: 'thin solid #056768',
          width: '100px'
        }}></div>
        </div>
        <div className="about-content">
          {children}
        </div>
      </div>
    </div>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Erik is a technologist and software developer with experience leading teams through the app development process from concept to product launch and beyond.  Erik helps stakeholders refine their ideas in light of their goals and identify actionable requirements and measurable success metrics.  He has designed data models and information systems to bring ad-hoc content and processes into the information age.  Erik has designed and developed user experiences through iterative prototyping and conducted quantitative and qualitative tests to validate them.  He has lead the design of pragmatic cross-platform UI Kits and design systems and gone on to implement them in web and iOS client applications.  Erik has lead the design and development of microservices to satisfy the needs of multi-tenant enterprise apps.  He also designs, develops and supports commercial REST and javascript API's and content management systems.`}</p>
    <p>{`Erik is a life-long learner, fascinated by the unlimited potential of technology to exchange ideas, provide services and simplify life.  Beyond technology, he is very interested in communication, collaboration and process improvement.  While Erik delights in programming, he is also excellent at managing knowledge gaps and overcoming communication challenges.  Erik is a leader, promoting a growth mindset and seeking and sharing inspiration with everyone around him.  He loves writing documentation, research and collaborative work.`}</p>
    <p>{`When Erik isn't behind the keyboard, he is busy raising his amazing daughter, spending time with his wife and friends, walking his dog, running or practicing yoga.  He might be getting after it on his surfboard, skateboard or mountain bike or he could be parked on his couch streaming EPL matches.  Erik loves to get out of town and frequents the Sierra Buttes, the California coast and Mexico.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      