import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import ImageMeditation from "../image-components/image-meditation"
import SEO from "../components/seo"

import About from "../mdx-components/about"
import BlogTeaser from "../components/blog-teaser"
import ContactTeaser from "../components/contact-teaser"

const IndexPage = () => (
  <Layout>
    <SEO title="Erik Altman Portfolio" description="Portfolio website and developer blog by Erik Altman'} keywords={'Erik Altman, blog, resume, portfolio, contact, web application developer, javascript, martinez, software, node, docker"/>
    <div>
      <div className="container">
        <div className="row flex-row flex-row-reverse vh-lg-90" >
          <div className="col-12 col-lg-4 d-flex justify-content-center align-items-center mt-3">
            <div style={imageMeditationContainerStyle} className="d-inline w-100 w-lg-max-50 mt-5">
              <ImageMeditation style={imageMeditationStyle} />
            </div>
          </div>
          <div className="col-12 col-lg-7 my-5 d-flex flex-column justify-content-center align-items-center text-center">
            <div className="container-fluid bg-white py-4 px-4">
              <h1 className="mb-4">Do you want help making technology products?</h1>
              <p>Erik Altman is an experienced technology project manager, application developer and UX/UI designer that can help your projects succeed.</p>
              <p>Contact Erik to find out how.</p>
              <Link to="/contact" className="btn btn-outline-primary">Contact Erik</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <About />
    <BlogTeaser />
    <ContactTeaser />
  </Layout>
)

const imageMeditationContainerStyle = {
  transform: 'translatey(0px)',
  animation: 'float 4s ease-in-out infinite',
}

const imageMeditationStyle = {
  maxWidth: 400,
  maxHeight: 400,
  boxShadow: '0 5px 15px 0px rgba(0,0,0,0.6)',
}

export default IndexPage
