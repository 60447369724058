import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

export default function Template() {
  const featuredImgFluid = (edge) => {
    return edge.node.frontmatter.featuredImage.childImageSharp.fluid
  }

  return (
    <StaticQuery
      query={componentQuery}
      render={data => (
        <div className="bg-light">
          <div className="container px-5 py-5">
            <div className="row">
              <div className="col-12">
                <div className="my-4">
                  <h2>Blog</h2>
                  <p>Latest posts from <Link to="/blog">The Mindful Technologist</Link></p>
                </div>
              </div>
              <div className="card-deck col-12">
                {
                  data.allMarkdownRemark.edges
                    .map((edge, i) =>
                      <div className="card shadow-sm" style={{minWidth: 200, marginBottom: 16}} key={i}>
                        <Img className="card-img-top" style={{ maxWidth: 750 }} fluid={featuredImgFluid(edge)} alt="Card image cap" />
                        <div className="card-body">
                          <h3 className="card-title">{edge.node.frontmatter.title}</h3>
                          <p className="card-text">{edge.node.frontmatter.description}</p>
                          <Link to={edge.node.frontmatter.path} className="btn btn-primary">Read More &rarr;</Link>
                        </div>
                      </div>
                    )
                }
              </div>
            </div>
          </div>
        </div>
      )}
    />
  )
}

export const componentQuery = graphql`
  query {
    allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 2
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            path
            author
            title
            date
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
